@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  h1 {
    @apply text-2xl;
  }
  h2 {
    @apply text-xl;
  }
  h3 {
    @apply text-lg;
  }

  a {
    @apply text-lime-600 hover:underline;
  }

  body {
    @apply bg-gray-100;
  }
}